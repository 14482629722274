<template>
  <div class="pt-3">
    <div
      class="spinner"
      :class="{'show-spinner': loadingData}"></div>
    <h4>Marketing Documents</h4>
    <div class="row">
      <div class="col-3">
        <v-select
          v-model="uploadedBy"
          :items="distinctUserDisplayName"
          clearable
          label="Uploaded By">
        </v-select>
      </div>
      <div class="col-2">
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto">
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="onOrAfterDate"
              label="On or After Date Uploaded"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <v-date-picker
            v-model="onOrAfterDate"
            :min="minOnOrAfterDate"
            :max="maxOnOrAfterDate || null"
            @input="dateMenu = false">
          </v-date-picker>
        </v-menu>
      </div>
      <div class="col-2">
        <div class="form-group search-group">
          <input
            v-model="search"
            type="text"
            class="form-control search-input"
            autocomplete="off"
            placeholder="Name" />
          <i class="material-icons search-icon">
            search
          </i>
        </div>
      </div>
      <div class="col-2">
        <div class="text-center">
          <v-dialog
            v-model="dialog"
            width="600">
            <template #activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mr-2"
                v-bind="attrs"
                v-on="on">
                Add Document
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">
                  Add Document
                </span>
              </v-card-title>
              <upload-single
                :type-id="marketingDocumentTypeId"
                :filter-type="'marketing'"
                @update="uploadComplete()"
                @mediaReplaceCancel="dialog = false">
              </upload-single>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <v-data-table
        v-if="!loadingData"
        :headers="tableHeader"
        :items="marketingDocumentsFiltered"
        :options="dataTableOptions"
        :loading="loadingData"
        :footer-props="{
          'items-per-page-options': [10, 25, 50]
        }"
        dense
        fixed-header
        light
        loading-text="Loading... Please wait">
        <template #[`item.name`]="{ item }">
          <a
            :href="getDocumentUrl(item)"
            target="_blank"
            rel="noopener"
            class="text-decoration-none">{{ item.name }}</a>
          <v-btn
            icon
            @click="copyUrlToClipboard(item)">
            <i
              style="cursor:pointer; font-size: 1.0rem;"
              class="material-icons">
              content_copy
            </i>
          </v-btn>
        </template>
        <template #[`item.uploadDate`]="{ item }">
          {{ formattedUploadedDate(item?.uploadDate) }}
        </template>
        <template #[`item.documentId`]="{ item }">
          <v-btn
            icon
            @click="showDeleteDocumentConfirmDialog(item)">
            <i
              style="cursor:pointer; font-size: 1.5rem;"
              class="material-icons">
              delete
            </i>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-model="showDeleteConfirmDialog"
      max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          Delete Document
        </v-card-title>
        <v-card-text>
          Are you sure you want to delete document?<br /><br /> <strong>{{ selectedDocument?.name }}</strong>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="deleteDocument()">
            Ok
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="showDeleteConfirmDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script type="text/javascript">
import UploadSingle from '../../media/upload/uploadSingle.vue'
import axios from 'axios'
import { format, parseISO } from 'date-fns'

export default {
  name: 'MarketingDocuments',
  components: {
    UploadSingle
  },
  data () {
    return {
      tableHeader: [
        { text: 'Name', value: 'name' },
        { text: 'Uploaded By', value: 'userDisplayName' },
        { text: '', value: 'url' },
        { text: 'Uploaded', value: 'uploadDate' },
        { text: '', value: 'documentId' }
      ],
      dataTableOptions: {
        itemsPerPage: this.isDashboard ? 5 : 10,
        sortBy: ['dateSubmitted'],
        sortDesc: [true]
      },
      marketingDocuments: [],
      loadingData: false,
      isDashboard: false,
      dateMenu: null,
      onOrAfterDate: null,
      minOnOrAfterDate: null,
      maxOnOrAfterDate: null,
      search: null,
      numberOfDaysBack: 365,
      dialog: false,
      marketingDocumentTypeId: 42,
      showSpinner: false,
      showDeleteConfirmDialog: false,
      selectedDocument: null,
      uploadedBy: null
    }
  },
  computed: {
    marketingDocumentsFiltered () {
      let list = []

      list = this.marketingDocuments

      if (this.search) {
        list = this.marketingDocuments.filter((record, index) => {
          return record.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        })
      }

      if (this.uploadedBy) {
        list = list.filter((record, index) => {
          return (record.userDisplayName.indexOf(this.uploadedBy) > -1)
        })
      }

      return list
    },
    isMarketingUser () {
      return this.$store.getters.hasPermissionForMarketing
    },
    distinctUserDisplayName () {
      return [...new Set(this.marketingDocuments.map(x => x.userDisplayName))].sort()
    }
  },
  mounted () {
    // Set displayed Filter Date based on numberOfDaysBack
    this.maxOnOrAfterDate = new Date().toISOString().slice(0, 10)
    const dateOffset = (24 * 60 * 60 * 1000) * this.numberOfDaysBack
    const myDate = new Date()
    myDate.setTime(myDate.getTime() - dateOffset)
    this.onOrAfterDate = myDate.toISOString().slice(0, 10) // Just get the Date Part
    this.minOnOrAfterDate = this.onOrAfterDate
  },
  created () {
    this.getAllMarketingDocuments()
  },
  methods: {
    getAllMarketingDocuments () {
      this.loadingData = true
      axios.get('/api/media/documents/marketing')
        .then(response => {
          this.marketingDocuments = response.data
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.loadingData = false
        })
    },
    formattedUploadedDate (dateTime) {
      return format(parseISO(dateTime), 'dd-MMM-yy HH:mm a').toLocaleUpperCase()
    },
    uploadComplete () {
      this.getAllMarketingDocuments()
    },
    showDeleteDocumentConfirmDialog (document) {
      this.selectedDocument = document
      this.showDeleteConfirmDialog = true
    },
    deleteDocument () {
      this.showDeleteConfirmDialog = false
      this.loadingData = true
      axios.delete(`/api/media/documents/${this.selectedDocument.documentId}/marketing`)
        .then(response => {
          this.getAllMarketingDocuments()
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.loadingData = false
          this.selectedDocument = null
        })
    },
    getDocumentUrl (item) {
      return `https://rexel-cdn.com/products/${item.name}?i=${item.folderName}`
    },
    async copyUrlToClipboard (item) {
      await navigator.clipboard.writeText(this.getDocumentUrl(item))
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/bootstrap.scss";
@import "../../../assets/scss/extra/mixins/flexbox.scss";
@import "../../../assets/scss/bootstrap-custom-variables.scss";
.status {
  &-valid {
    color: #00a14d;
  }
  &-pending-approval {
    color: rgb(255, 136, 0);
  }
  &-invalid {
    color: #a00;
  }
}

.cloud-download {
  font-size: 24px;
}

.icon-red {
  color: $brand-danger;
}

.search-group {
  position: relative;
  .search-input {
    width: 100%;
    font-size: 0.875rem;
    color: #000;
    line-height: 1.42857143;
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    &:focus {
      border-color: $brand-primary;
      & ~ i {
        -webkit-transition: all ease-in-out 0.15s,
          -webkit-box-shadow ease-in-out 0.15s;
        transition: all ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        color: $brand-primary;
        opacity: 1;
      }
    }
  }
  .search-icon {
    position: absolute;
    font-size: 1.5rem;
    top: 0.5rem;
    right: 0.5rem;
    opacity: 0.4;
    padding: 0.25rem;
    &:hover {
      cursor: pointer;
      -webkit-transition: all ease-in-out 0.15s,
        -webkit-box-shadow ease-in-out 0.15s;
      transition: all ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      color: $brand-primary;
      opacity: 1;
    }
  }
}
</style>
