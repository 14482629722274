<template>
  <modal
    ref="bulkExportModal"
    :icon="modal.icon"
    :blocking="modal.blockClose"
    :title="title ? title : modal.title"
    @close="closeModal()">
    <div>
      <template v-if="showModal">
        <div
          v-if="byType"
          class="type-filter">
          <div>
            <span>Select a template type</span>
            <select
              v-model="typeFilter"
              @change="onSelectionChanged()">
              <option
                value="1"
                selected>
                Global Values
              </option>
              <option value="2">
                Banner Specific
              </option>
              <option value="3">
                Attributes
              </option>
              <option value="4">
                Media
              </option>
              <option value="5">
                Associations
              </option>
              <option value="6">
                Product Matching
              </option>
            </select>
          </div>
          <hr v-if="typeFilter === '1' || typeFilter === '2'" />
        </div>
        <template>
          <label
            v-if="typeFilter === '1' || typeFilter === '2'"
            class="company-label">
            {{ label }}
          </label>
          <div :hidden="typeFilter !== '1'">
            <ul class="list-group export-checks">
              <li class="list-group-item no-gutters">
                <div class="col">
                  <input
                    id="checkbox-english"
                    v-model="english"
                    type="checkbox" />
                  <label
                    class="export-switch-label"
                    :class="{'text-primary': english}">English</label>
                </div>
              </li>
              <li class="list-group-item no-gutters">
                <div class="col">
                  <input
                    id="checkbox-french"
                    v-model="french"
                    type="checkbox"
                    :disabled="true" />
                  <label
                    class="export-switch-label"
                    :class="{'text-primary': french}">French</label>
                </div>
              </li>
              <li class="list-group-item no-gutters">
                <div class="col">
                  <input
                    id="checkbox-spanish"
                    v-model="spanish"
                    type="checkbox"
                    :disabled="true" />
                  <label
                    class="export-switch-label"
                    :class="{'text-primary': spanish}">Spanish</label>
                </div>
              </li>
            </ul>
          </div>
          <div :hidden="typeFilter !== '2'">
            <ul class="list-group export-checks">
              <li class="list-group-item no-gutters">
                <div class="col">
                  <input
                    id="check-platt-company"
                    v-model="platt"
                    type="checkbox" />
                  <label
                    class="export-switch-label"
                    :class="{'text-primary': platt}">
                    Platt <small>(Company Specific)</small>
                  </label>
                </div>
              </li>
              <li class="list-group-item no-gutters">
                <div class="col">
                  <input
                    id="check-gexpro-company"
                    v-model="gexpro"
                    type="checkbox" />
                  <label
                    class="export-switch-label"
                    :class="{'text-primary': gexpro}">
                    Gexpro <small>(Company Specific)</small>
                  </label>
                </div>
              </li>
              <li class="list-group-item no-gutters">
                <div class="col">
                  <input
                    id="check-rexel-company"
                    v-model="rexel"
                    type="checkbox" />
                  <label
                    class="export-switch-label"
                    :class="{'text-primary': rexel}">
                    Rexel
                    <small>(Company Specific)</small>
                  </label>
                </div>
              </li>
              <li class="list-group-item no-gutters">
                <div class="col">
                  <input
                    id="check-rexelca-company"
                    v-model="rexelCanada"
                    type="checkbox" />
                  <label
                    class="export-switch-label"
                    :class="{'text-primary': rexel}">
                    Rexel Canada <small>(Company Specific)</small>
                  </label>
                </div>
              </li>
              <li class="list-group-item no-gutters">
                <div class="col">
                  <input
                    id="check-psc-company"
                    v-model="psc"
                    type="checkbox" />
                  <label
                    class="export-switch-label"
                    :class="{'text-primary': psc}">
                    Parts Super Center <small>(Company Specific)</small>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div :hidden="typeFilter !== '4'">
            <ul class="list-group export-checks">
              <li class="list-group-item no-gutters">
                <center>
                  <!-- <p>The media template requires no configuration, click the button to download the template.</p> -->
                  <a
                    href="/assets/templates/BulkMedia_Template.csv"
                    class="btn btn-primary mb-2">Download Media Template</a>
                </center>
              </li>
            </ul>
          </div>

          <div :hidden="typeFilter !== '5'">
            <ul class="list-group export-checks">
              <li class="list-group-item no-gutters">
                <center>
                  <!-- <p>The association template requires no configuration, click the button to download the template.</p> -->
                  <a
                    href="/assets/templates/Associations_Template.xlsx"
                    class="btn btn-primary mb-2">Download Associations Template</a>
                </center>
              </li>
            </ul>
          </div>
          <div :hidden="typeFilter !== '6'">
            <ul class="list-group export-checks">
              <li class="list-group-item no-gutters">
                <center>
                  <!-- <p>The product matching template requires no configuration, click the button to download the template.</p> -->
                  <a
                    href="/assets/templates/ProductMatching_Template.xlsx"
                    class="btn btn-primary mb-2">Download Product Matching Template</a>
                </center>
              </li>
            </ul>
          </div>
        </template>
      </template>
    </div>
    <div
      v-if="showCloseAndExportButtons"
      slot="button">
      <button
        type="button"
        class="btn btn-gray-alt"
        @click="closeModal()">
        Close
      </button>
      <button
        v-show="typeFilter !== 'media'"
        type="button"
        class="btn btn-primary"
        :disabled="exportDisabled"
        @click="bulkExport()">
        Export
      </button>
    </div>
  </modal>
</template>
<script type="text/javascript">
import Modal from 'components/modals/sweet.vue'
// import ToggleSwitch from 'components/inputs/toggleSwitch.vue'

export default {
  name: 'BulkExportTool',
  components: {
    modal: Modal
    // toggle: ToggleSwitch
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
      required: true
    },
    ids: {
      type: Array,
      default: null,
      required: false
    },
    taskId: {
      default: null,
      required: false
    },
    taskExport: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: 'Bulk Export Tool',
      required: false
    },
    label: {
      type: String,
      default: 'Sections to include:',
      required: false
    },
    byType: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      singleValues: false,
      attributes: false,
      platt: false,
      rexel: false,
      gexpro: false,
      psc: false,
      rexelCanada: false,
      english: true,
      french: false,
      spanish: false,
      modal: {
        title: this.title ? this.title : 'Bulk Export Tool',
        icon: null,
        blockClose: true
      },
      typeFilter: '1'
    }
  },
  computed: {
    exportDisabled () {
      // Do not disable for Attributes
      if (this.typeFilter === '3') {
        return false
      }
      const languageSelected = this.english || this.french || this.spanish
      const bannerSpecificSelected = this.platt || this.rexel || this.gexpro || this.rexelCanada || this.psc
      const attributesSelected = this.attributes
      return !languageSelected && !bannerSpecificSelected && !attributesSelected
    },
    showCloseAndExportButtons () {
      return this.typeFilter === '1' || this.typeFilter === '2' || this.typeFilter === '3'
    }
  },
  watch: {
    showModal (value) {
      if (value) {
        this.$refs.bulkExportModal.open()
      } else {
        this.$refs.bulkExportModal.close()
      }
    }
  },
  methods: {
    onSelectionChanged () {
      this.attributes = this.typeFilter === '3'
      this.platt = false
      this.rexel = false
      this.rexelCanada = false
      this.gexpro = false
      this.psc = false
      this.english = this.typeFilter === '1' // Always true for Global Values
      this.french = false
      this.spanish = false
    },
    closeModal () {
      this.$emit('update:showModal', false)
      this.singleValues = false
      this.attributes = false
      this.platt = false
      this.rexel = false
      this.rexelCanada = false
      this.gexpro = false
      this.psc = false
      this.english = false
      this.french = false
      this.spanish = false
    },
    bulkExport () {
      const exportOptions = {
        ids: this.ids,
        singleValues: this.singleValues,
        attributes: this.attributes,
        platt: this.platt,
        rexel: this.rexel,
        gexpro: this.gexpro,
        rexelCanada: this.rexelCanada,
        psc: this.psc,
        english: this.english,
        french: this.french,
        spanish: this.spanish
      }

      if (this.taskExport) {
        exportOptions.ids = null
        exportOptions.taskMasterEditId = this.taskId
      }

      this.$emit('export', exportOptions)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/extra/mixins/flexbox.scss";
.type-filter {
  span {
    font-size: 1rem;
  }
  select {
    min-width: 15rem;
    margin-left: 0.5rem;
    font-size: 1rem;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.company-label {
  font-size: 1rem;
  color: #303030;
  font-weight: 500;
}

.export-checks {
  li {
    border-right: 0;
    border-left: 0;
    padding: 0;
    &:first-child {
      border-top: 0;
    }
    > div.col {
      padding: 0.5rem 1rem;
    }
    .material-switch {
      display: inline-block;
      padding-top: 0.25rem;
    }
    .export-switch-label {
      font-size: 0.875rem;
      padding: 0 1rem;
      margin: 0.25rem 0 0;
      vertical-align: top;
    }
  }
}

.sweet-buttons {
  > div {
    @include flexbox;
    @include justify-content(space-between);
    .btn {
      font-size: 0.875rem;
      min-width: 8.25rem;
      cursor: pointer;
    }
  }
}
</style>
