<template>
  <div class="card-block">
    <div class="row">
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Parts Super Center Item Number
          </label>
          <input
            v-model="product.productId"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Product Number
          </label>
          <input
            v-model="product.sku"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            PSC Price Line
          </label>
          <input
            v-model="product.mfrCode"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Buy Line
          </label>
          <input
            v-model="product.buyLine"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            UOM
          </label>
          <input
            v-model="product.uom"
            type="text"
            class="form-control"
            :disabled="isReadOnly" />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Multiple Order Quantity
          </label>
          <input
            v-model="product.minOrdQty"
            type="number"
            class="form-control hide-arrows"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Vendor Group Code 2
          </label>
          <input
            v-model="product.vendorGroup"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Vendor Group Code 3
          </label>
          <input
            v-model="product.unitSales"
            type="text"
            class="form-control"
            disabled />
        </div>
      </div>
      <div class="col-md-6 col-lg-3">
        <div class="form-group">
          <label class="form-control-label">
            Replaced By
          </label>
          <input
            v-model="product.bestSellerPriority"
            type="number"
            class="form-control hide-arrows"
            disabled />
        </div>
      </div>
    </div>
    <v-row>
      <v-col :cols="3">
        <v-select
          v-model="product.showOnWeb"
          v-tooltip="'Not set interpreted as yes for Platt and no for others'"
          class="mb-2"
          :items="showOnWebItems"
          :disabled="isReadOnly"
          hide-details
          outlined
          dense
          label="Show On Web">
        </v-select>
      </v-col>
      <v-col :cols="2">
        <label class="custom-control custom-checkbox">
          <input
            v-model="product.callForPricing"
            type="checkbox"
            class="custom-control-input"
            :disabled="isReadOnly" />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">
            Call For Pricing
          </span>
        </label>
      </v-col>
      <v-col :cols="2">
        <label class="custom-control custom-checkbox">
          <input
            v-model="product.visibleOnlyToCustomers"
            type="checkbox"
            class="custom-control-input"
            :disabled="isReadOnly" />
          <span class="custom-control-indicator"></span>
          <span class="custom-control-description">
            Only Visible to Customers
          </span>
        </label>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PermissionsMixin from 'mixins/Permissions.mixin'
export default {
  components: {
  },
  props: {
    product: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      marketOptions: [],
      showOnWebItems: [
        {
          text: 'Not set',
          value: null
        },
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ]
    }
  },
  computed: {
    isReadOnly () {
      return !this.$store.getters.hasPermissionForBanner(PermissionsMixin.data.Roles.contributor, 15)
    }
  },
  watch: {
    product: {
      handler (val) {
        this.$emit('bannerPropChange', 'Parts Super Center')
      },
      deep: true
    }
  }
}
</script>
