<template id="sidebar-component">
  <aside id="sidebar">
    <div
      v-if="user"
      class="sidebar-user dropdown">
      <div @click="openMyProfile()">
        <avatar
          :username="user.name"
          :size="40"></avatar>
      </div>
      <div class="user-info">
        <p class="user-department">
          Department
        </p>
        <p class="user-fullname">
          {{ user.name }}
        </p>
      </div>
    </div>

    <div
      v-if="user?.companyPermissions && isMarketingUser === false"
      class="sidebar-main row no-gutters">
      <div class="col">
        <ul class="nav flex-column">
          <span class="nav-section-header mt-3">
            Content
          </span>
          <router-link
            tag="li"
            :to="{name: 'dashboard'}"
            class="nav-item"
            exact>
            <a class="nav-link">
              <i class="material-icons">
                timeline
              </i>
              <span class="nav-link-text">
                Dashboard
              </span>
            </a>
          </router-link>
          <router-link
            replace
            :to="{name: 'productSearch'}">
            <a class="nav-link">
              <i class="material-icons">
                search
              </i>
              <span class="nav-link-text">
                Find
              </span>
            </a>
          </router-link>
          <a
            class="btn nav-link"
            color="white"
            data-toggle="collapse"
            href="#productsLink"
            aria-expanded="false"
            aria-controls="productsLink">
            <i class="material-icons">
              loyalty
            </i>
            <span class="nav-link-text">
              Products
            </span>
            <i
              class="material-icons">
              keyboard_arrow_down
            </i>
          </a>
          <div
            id="productsLink"
            class="collapse">
            <router-link
              v-if="companyIsEditor"
              :to="{path: '/categories'}">
              <a
                href="/categories"
                class="nav-link">
                <span class="nav-link-text">
                  Categories
                </span>
              </a>
            </router-link>
            <router-link
              v-if="companyIsEditor"
              :to="{name: 'attributesList'}"
              class="nav-item">
              <a class="nav-link">
                <span class="nav-link-text">
                  Attributes
                </span>
              </a>
            </router-link>
            <router-link
              v-if="companyIsAdmin || companyIsEditor"
              :to="{path: '/sustainability'}"
              class="nav-item">
              <a class="nav-link">
                <span class="nav-link-text">
                  Sustainability
                </span>
              </a>
            </router-link>
          </div>
          <a
            class="btn nav-link"
            data-toggle="collapse"
            href="#approvalsLink"
            aria-expanded="false"
            aria-controls="approvalsLink">
            <i class="material-icons">
              &#xE862;
            </i>
            <span class="nav-link-text">
              Approvals
            </span>
            <i class="material-icons">
              keyboard_arrow_down
            </i>
          </a>
          <div
            id="approvalsLink"
            class="collapse">
            <router-link
              :to="{name: 'reviews'}"
              class="nav-item">
              <a class="nav-link">
                <span class="nav-link-text">
                  Reviews
                </span>
              </a>
            </router-link>
            <router-link
              v-if="companyIsEditor"
              :to="{name: 'productQnA', params: {status: 'pending'}}"
              class="nav-item">
              <a class="nav-link">
                <span class="nav-link-text">
                  Q &amp; A
                </span>
              </a>
            </router-link>
            <router-link
              v-if="companyIsEditor"
              :to="{name: 'slangTerms', params: {status: 'pending'}}"
              class="nav-item">
              <a class="nav-link">
                <span class="nav-link-text">
                  Slang
                </span>
              </a>
            </router-link>
          </div>
          <router-link
            tag="li"
            :to="{name: 'media'}"
            class="nav-item">
            <a
              href="/media"
              class="nav-link">
              <i class="material-icons">
                video_library
              </i>
              <span class="nav-link-text">
                Media
              </span>
            </a>
          </router-link>
          <router-link
            v-if="companyIsAdmin || companyIsEditor"
            tag="li"
            :to="{name: 'manufacturerList'}"
            class="nav-item">
            <a class="nav-link">
              <i class="material-icons">
                business
              </i>
              <span class="nav-link-text">
                Manufacturers
              </span>
            </a>
          </router-link>
          <router-link
            v-if="companyIsAdmin || companyIsEditor"
            :to="{name: 'bulk'}"
            class="nav-item">
            <a class="nav-link">
              <i class="material-icons">
                file_upload
              </i>
              <span class="nav-link-text">
                Uploads (Bulk)
              </span>
            </a>
          </router-link>
          <router-link
            tag="li"
            :to="{name: 'reports'}"
            class="nav-item">
            <a class="nav-link">
              <i class="material-icons">
                insert_chart
              </i>
              <span class="nav-link-text">
                Reports
              </span>
            </a>
          </router-link>
          <router-link
            v-if="companyIsAdmin"
            tag="li"
            :to="{name: 'teamBuilderList'}"
            class="nav-item">
            <a class="nav-link">
              <i class="material-icons">
                &#xE8D3;
              </i>
              <span class="nav-link-text">
                Team Builder
              </span>
            </a>
          </router-link>
          <router-link
            v-else
            tag="li"
            :to="{name: 'userDetail', params: {id: $store.getters.user.userId || ' ' }}"
            class="nav-item">
            <a class="nav-link">
              <i class="material-icons">
                person
              </i>
              <span class="nav-link-text">
                User Profile
              </span>
            </a>
          </router-link>
          <v-divider
            class="mr-3"
            color="white"></v-divider>

          <span class="mb-3 nav-section-header mt-1">
            Marketing
          </span>
          <router-link
            tag="li"
            :to="{name: 'externalContent', query: {contentName: 'promos'}, params: {externalUrl: getExternalUrl('promos') }}"
            class="nav-item">
            <a class="nav-link">
              <i class="material-icons">
                upgrade
              </i>
              <span class="nav-link-text">
                Promotions
              </span>
            </a>
          </router-link>
          <router-link
            tag="li"
            :to="{name: 'externalContent', query: {contentName: 'training'}, params: {externalUrl: getExternalUrl('training') }}"
            class="nav-item">
            <a class="nav-link">
              <i class="material-icons">
                school
              </i>
              <span class="nav-link-text">
                Training
              </span>
            </a>
          </router-link>
          <router-link
            tag="li"
            :to="{name: 'externalContent', query: {contentName: 'tracked-search'}, params: {externalUrl: getExternalUrl('tracked-search') }}"
            class="nav-item">
            <a class="nav-link">
              <i class="material-icons">
                search
              </i>
              <span class="nav-link-text">
                Tracked Search
              </span>
            </a>
          </router-link>
          <router-link
            tag="li"
            :to="{name: 'externalContent', query: {contentName: 'search-suppression'}, params: {externalUrl: getExternalUrl('search-suppression') }}"
            class="nav-item">
            <a class="nav-link">
              <i class="material-icons">
                lock
              </i>
              <span class="nav-link-text">
                Search Suppression
              </span>
            </a>
          </router-link>
        </ul>
      </div>
    </div>
  </aside>
</template>

<script type="text/javascript">
import UserAvatar from 'components/UserAvatar.vue'
import PermissionsMixin from 'mixins/Permissions.mixin'

import { mapActions } from 'vuex'

export default {
  name: 'SidebarComponent',
  components: {
    avatar: UserAvatar
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: null
    }
  },
  data () {
    return {
      notifications: {}
    }
  },
  computed: {
    companyIsEditor () {
      return this.$store.getters.hasPermissionForAnyBanner(PermissionsMixin.data.Roles.editor)
    },
    companyIsAdmin () {
      return this.$store.getters.hasPermissionForAnyBanner(PermissionsMixin.data.Roles.admin)
    },
    languageId () {
      return this.$store.getters.languageId
    },
    isMarketingUser () {
      return this.$store.getters.hasPermissionForMarketing
    }
  },
  methods: {
    ...mapActions(['setExternalUrl']),
    companyHasPermission (permission) {
      return !this.$store.getters.user.companyPermissions ? false : this.$store.getters.user.companyPermissions.some(c => c[permission])
    },
    openMyProfile () {
      if (this.isMarketingUser) {
        return
      }
      const externalUrl = this.$store.state.appConfig.environment === 'Production' ? 'https://myprofile.rexelapps.com' : 'https://testmyprofile.rexelapps.info'
      this.setExternalUrl(externalUrl)
    },
    getExternalUrl (contentName) {
      return `${this.$store.state.appConfig.marketingContentUrl}/${contentName}?external=true`
    }
  }
}
</script>
<style lang="scss">
@import '../../assets/scss/bootstrap-custom-variables';
@import '../../assets/scss/extra/mixins/flexbox';
@import '../../assets/scss/layout.scss';

#sidebar {
  visibility: visible;
  background-color: $brand-primary;
  width: 230px;

  .sidebar-user {
    @include flexbox;
    //@include flex-wrap(wrap);
    @include flex-direction(row);
    @include align-items(center);

    height: 3.75rem;
    padding: .375rem 1rem;
    background-color: darken($brand-primary, 5%);
    &.dropdown {
      .dropdown-menu {
        padding: .25rem 0;
        font-size: .85rem;
        .material-icons {
          color: $brand-danger;
        }
      }
    }
    .user-info,
    .user-additional-actions {
      display: inline-block;
      vertical-align: middle;
    }
    .user-info {
      padding-left: .625rem;
      p {
        margin: 0;
      }
      .user-department {
        color: lighten($brand-primary, 15%);

        text-transform: uppercase;
        font-size: .625rem;
        font-weight: 600;
        line-height: .625rem;
      }
      .user-fullname {
        color: #fff;

        font-size: .75rem;
      }
    }
    .user-info-wrap {
      margin-top: -.25rem;
    }
  }
  .sidebar-main {
    .nav {
      .router-link-exact-active {
        position: relative;
        a.nav-link {
          background-color: #0084d8;
          font-weight: bold;
          // line-height: 1.125rem;
          &:hover {
            background-color: #0084d8;
            font-weight: bold;
          }
        }
        &:after {
          content: '';
          width: 0;
          height: 0;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          border-top: 5px solid transparent;
          border-right: 5px solid #fff;
          border-bottom: 5px solid transparent;
        }
      }
    }
    a.nav-link {
      position: relative;
      padding: 0.75rem;
      display: flex;
      flex: 1 1 auto;
      color: #fff;
      &:hover {
        background-color: darken($brand-primary, 5%);
      }
      .material-icons,
      .nav-link-text,
      .notification-badge {
        align-self: center;
      }
      .material-icons {
        font-size: 1.375rem;
      }
      .nav-link-text {
        padding-left: .85rem;
        text-transform: uppercase;
        font-size: .75rem;
      }
      .notification-badge {
        background: #e5f9ef;
        border-radius: 50%;
        min-width: 18px;
        min-height: 18px;
        margin-left: auto;
      }
    }
  }
.dropdown-menu {
    padding-top: 0.25rem;
    &:before,
    &:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      right: 50%;
      content: "";
    }
    &:before {
      border-width: 11px;
      top: -11px;
      margin-right: -11px;
      border-bottom-color: rgba(0, 0, 0, 0.25);
      border-top-width: 0;
    }
    &:after {
      border-width: 10px;
      border-top-width: 0;
      top: -10px;
      margin-right: -10px;
      border-bottom-color: #fff;
    }
  }
}
.dropdown-header {
      padding-bottom: 0;
}
.nav-section-header {
  color: #fff;
  text-transform: uppercase;
  font-size: .75rem;
  padding-bottom: 5px;
}
.external-nav-link {
  color: #fff;
  font-size: .75rem;
}
.v-application ol, .v-application ul {
    padding-left: 10px;
}
</style>
