<template>
  <div class="media-upload">
    <div class="card upload">
      <p>Drag &amp; Drop</p>
      <span v-if="!isMarketingDocument">
        Or
      </span>
      <span v-else>
        <strong>(.PDF Only)</strong>
      </span>
      <button
        v-if="!isMarketingDocument"
        type="button"
        class="btn btn-primary"
        style="z-index: 3"
        @click="openBrowsingDisplay()">
        Browse
      </button>
      <template v-if="!resetUploadInput">
        <input
          type="file"
          class="file-upload"
          :accept="mediaFilterTypes"
          @change="uploadSingleMedia($event)" />
      </template>
    </div>

    <modal
      ref="uploadModal"
      :title="modal.title"
      :icon="modal.icon"
      :hide-close-button="loading"
      :animate-icon="modal.animate"
      :blocking="(uploadType === 'message') ? false : true"
      :large="modal.large"
      :small="modal.small"
      :no-padding="modal.noPadding"
      @close="resetModal()">
      <!-- SUCCESS MESSAGE -->
      <template v-if="uploadType === 'message'">
        <p class="lead text-center">
          {{ modal.message }}
        </p>
      </template>
      <!-- BROWSING -->
      <template v-else-if="uploadType === 'browsing'">
        <template v-if="!showUploadSpinner">
          <div class="drag-drop">
            <i class="material-icons">
              cloud_upload
            </i>
            <h5>Drag &amp; Drop Files Here</h5>
            <template v-if="!resetUploadInput">
              <input
                type="file"
                class="file-upload"
                :accept="mediaFilterTypes"
                @change="uploadSingleMedia($event)" />
            </template>
          </div>
        </template>
        <div class="row mx-2 mb-2 wrap">
          <template v-if="!showUploadSpinner">
            <div class="col-12 col-md-10">
              <div class="form-group">
                <input
                  v-model="uploadUri"
                  type="text"
                  class="form-control"
                  placeholder="Enter url..." />
              </div>
            </div>
            <div class="col-4 col-md-2 pl-md-0">
              <button
                type="button"
                class="btn btn-primary"
                :disabled="!uploadUri"
                @click="uploadUrl()">
                Import Url
              </button>
            </div>
          </template>

          <div
            v-else
            class="row my-5">
            <div class="my-5">
              <uploading-spinner :loading="showUploadSpinner"></uploading-spinner>
            </div>
          </div>

          <div
            v-if="showUploadUrlError"
            class="col-12">
            <div
              class="alert alert-danger"
              role="alert">
              {{ uploadUrlErrorMsg }}
            </div>
          </div>
        </div>
      </template>
      <!-- FILE TYPE -->
      <template v-else>
        <uploading-spinner :loading="loading"></uploading-spinner>

        <div :class="{'uploading-media': loading}">
          <template v-if="uploadType === 'image'">
            <upload-image-form
              :image.sync="imageToUpload"
              :existing="mediaReplace"
              @imageTooSmall="(tooSmall) => validation.media = tooSmall"></upload-image-form>
          </template>

          <template v-else-if="uploadType === 'document'">
            <div class="media-wrap">
              <icon
                :type="'Document'"
                :file="documentToUpload.fileName"></icon>
              <label class="file-name">
                {{ documentToUpload.fileName }}
              </label>
            </div>
            <div
              v-if="!mediaReplace && !isMarketingDocument"
              class="media-info">
              <div class="form-group">
                <label class="form-control-label form-label-required">
                  Document Type:
                </label>
                <select
                  v-model="documentToUpload.type"
                  class="form-control"
                  :class="{'is-invalid': validation.documentType && !documentToUpload.type}">
                  <option
                    v-for="d in documentTypes"
                    :key="d.documentTypeId"
                    :value="d.documentTypeId">
                    {{ d.name }}
                  </option>
                </select>
              </div>
              <label class="form-control-label">
                Manufacturer:
              </label>
              <select
                v-model="documentToUpload.manufacturer"
                class="form-control">
                <option
                  v-for="m in manufacturers"
                  :key="m.id"
                  :value="m.id">
                  {{ m.name }}
                </option>
              </select>
            </div>
          </template>

          <template v-else-if="uploadType === 'video'">
            <div class="media-wrap">
              <icon
                :type="'Video'"
                :file="videoToUpload.fileName"></icon>
              <label class="file-name">
                {{ videoToUpload.fileName }}
              </label>
            </div>
            <div
              v-if="!mediaReplace"
              class="media-info">
              <label class="form-control-label">
                Manufacturer:
              </label>
              <select
                v-model="videoToUpload.manufacturer"
                class="form-control">
                <option
                  v-for="m in manufacturers"
                  :key="m.id"
                  :value="m.id">
                  {{ m.name }}
                </option>
              </select>
            </div>
            <p class="pt-2 mb-0">
              *Video may take a few minutes to be processed after upload.
            </p>
          </template>

          <template v-else-if="uploadType === 'error'">
            <div
              class="alert alert-danger small-img-msg"
              role="alert">
              There was an error uploading your file. Please try again.
            </div>
          </template>

          <template v-else>
            <div
              class="alert alert-danger small-img-msg"
              role="alert">
              We currently do not support that type of media upload, sorry for the inconvenience.
            </div>
          </template>
        </div>
      </template>

      <div
        v-if="uploadType !== 'browsing'"
        slot="button"
        class="modal-actions">
        <template v-if="uploadType === 'message'">
          <div></div>
          <button
            type="button"
            class="btn btn-primary"
            @click="resetState($event)">
            Done
          </button>
        </template>
        <template v-else>
          <button
            type="button"
            class="btn btn-link"
            :disabled="loading"
            @click="resetState($event)">
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :class="{ 'disabled': validateBtn() } "
            :disabled="validateBtn()"
            :hidden="uploadType === 'error'"
            @click="save($event)">
            Upload
          </button>
        </template>
      </div>
    </modal>
  </div>
</template>
<script type="text/javascript">
import UploadMediaMixin from './UploadMedia.mixin'
import UploadImageForm from '@/views/media/types/image/UploadImageForm'
// import Cropper from 'components/crop'
import LoadingComponent from './uploading.vue'
import Modal from 'components/modals/sweet.vue'
import IconComponent from '../mediaIcon.vue'
import { EventBus } from '../bus'

export default {
  name: 'NewMedia',
  components: {
    modal: Modal,
    // cropper: Cropper,
    icon: IconComponent,
    'uploading-spinner': LoadingComponent,
    UploadImageForm
  },
  mixins: [UploadMediaMixin],
  props: {
    manufacturers: Array,
    documentTypes: Array,
    filterType: String,
    mediaReplace: Boolean,
    typeId: Number,
    mediaId: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      resetUploadInput: false,
      loading: false,
      modal: {
        title: 'Upload Media',
        icon: null,
        animate: false,
        message: '',
        large: false,
        small: false,
        noPadding: false
      }
    }
  },
  computed: {
    mediaFilterTypes () {
      const filters = this.filterType ? [this.filterType] : []
      return this.getValidMediaTypes(filters)
    },
    isMarketingDocument () {
      return this.typeId === 42
    }
  },
  watch: {
    uploadMessage (value) {
      setTimeout(() => {
        this.$refs.uploadModal._animateIcon()
      }, 50)
    },
    uploadType (value) {
      if (value === 'browsing') {
        this.modal.title = 'Upload Media'
        this.modal.noPadding = true
        this.modal.small = false
        this.modal.large = true
      } else if (value === 'message') {
        this.modal.title = 'Upload Successful'
        this.modal.noPadding = false
        this.modal.small = false
        this.modal.large = true
      } else if (value === 'error') {
        this.modal.title = 'Unsuccessful Upload'
        this.modal.icon = 'error'
      } else {
        if (value === 'image') {
          this.modal.title = 'Upload Image'
        } else if (value === 'video') {
          this.modal.title = 'Upload Video'
        } else if (value === 'document') {
          this.modal.title = 'Upload Document'
          this.documentToUpload.type = this.typeId
        }
        this.modal.noPadding = value === 'image'
        this.modal.small = value !== 'image'
        this.modal.large = value === 'image'
      }
    }
  },
  mounted () {
    if (this.typeId) {
      this.documentToUpload.type = this.typeId
    }
  },
  methods: {
    openBrowsingDisplay () {
      this.uploadType = 'browsing'
      this.$refs.uploadModal.open()
    },
    save (event) {
      if (!this.isValidMedia()) {
        return
      }
      this.loading = true
      if (this.mediaReplace) {
        this.documentToUpload.id = this.mediaId
        this.imageToUpload.mediaId = this.mediaId
        this.videoToUpload.id = this.mediaId
        this.replaceMedia()
          .then(data => {
            this.loading = false
            this.showSuccessMessage(data)
            EventBus.$emit('mediaReplace', data)
          }).catch(e => {
            this.loading = false
            this.showErrorMessage()
          })
      } else {
        this.saveMedia()
          .then((data) => {
            this.loading = false
            this.showSuccessMessage(data)
            EventBus.$emit('mediaReplace', data)
          })
          .catch(e => {
            this.loading = false
            this.showErrorMessage()
          })
      }
    },
    showSuccessMessage (item) {
      this.$emit('update')
      this.modal.icon = 'success'
      let mediaType = item.mediaType
      // if this is a document upload (mediaType is undefined), then get document type instead.
      if (!mediaType) {
        mediaType = item.documentType
      }
      this.modal.message = `Your ${mediaType} upload has been successfully uploaded.`
      this.$nextTick(() => {
        this.uploadType = 'message'
      })
    },
    showErrorMessage () {
      this.$nextTick(() => {
        this.uploadType = 'error'
      })
    },
    validateBtn () {
      return this.loading || this.invalidMediaDocument()
    },
    uploadSingleMedia (event) {
      this.uploadMedia(event)
      if (!this.$refs.uploadModal.visible) {
        this.$refs.uploadModal.open()
      }
    },
    resetModal () {
      this.loading = false
      this.modal = {
        title: 'Upload Media',
        icon: null,
        animate: false,
        message: '',
        large: false,
        small: false,
        noPadding: false
      }
      this.resetUploadDefaults()
      this.resetUploadInput = true
      this.$nextTick(() => {
        this.resetUploadInput = false
      })
    },
    resetState (event) {
      this.$emit('mediaReplaceCancel')
      this.resetModal()
      this.$refs.uploadModal.close()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../assets/scss/extra/mixins/flexbox.scss";
@import "../../../assets/scss/bootstrap-custom-variables.scss";
.media-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  min-height: 12rem;
  margin: 0.5rem;
  width: 100%;
  height: 100%;
  @include flexbox;
  @include flex(0, 1, 8.75rem);

  .card.upload {
    @include justify-content(center);
    @include align-items(center);
    @include flex(1, 1, 100%);
    border: 2px dotted $gray;
    > p {
      text-align: center;
      margin-bottom: 0;
    }
    > span {
      text-align: center;
    }
    .btn {
      width: 70%;
      border-radius: 25px;
      @include align-self(center);
    }
    input.file-upload {
      opacity: 0;
      z-index: 2;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }
}

.media-wrap {
  display: block;
  color: $gray-600;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  text-align: center;
}
.drag-drop {
  background: $gray-300;
  @include flexbox;
  @include align-items(center);
  @include flex-direction(column);
  @include justify-content(center);
  box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  min-height: 13.5rem;
  padding: 1rem 1rem 2rem;
  margin-bottom: 1rem;
  .material-icons {
    font-size: 4rem;
  }
  > h5 {
    margin: 1rem 0 1.5rem;
    color: $gray;
  }
  .btn {
    min-width: 10rem;
    font-weight: 500;
  }
  input.file-upload {
    opacity: 0;
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  &:hover,
  &:active,
  &:focus {
    background: darken($gray-300, 5%);
  }
}
.modal-actions {
  @include flexbox;
  @include justify-content(space-between);
  .btn {
    min-width: 7rem;
  }
}
</style>
